/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Fragment } from "react"
import { animated as a, useSpring } from "react-spring"

import TableRow from "./TableRow"
import AudioButton from "../AudioButton"
import { translate, fade } from "../../utils/animate"
import language from "../../config/language"

export default ({ data, audio, format }) => {
  function renderSpecial(translation) {
    switch (format.key) {
      case "all":
        return (
          <Fragment>
            <div className="characters">{translation.characters}</div>
            <div className="romanize">{translation.romanization}</div>
          </Fragment>
        )
      case "romanize":
        return <div>{translation.romanization}</div>
      case "characters":
        return <div className="characters">{translation.characters}</div>
      default:
        return
    }
  }

  function formatCells(rows) {
    return rows.map(({ base, translation }, index) => {
      return language.special ? (
        <TableRow
          key={base}
          base={base}
          className="edit-row"
          translation={translation}
          sx={{
            borderBottom: language.special ? "1px solid #eee" : "none",
            ":last-child": {
              border: "none",
            },
          }}
        >
          <td>{base}</td>
          <a.td
            sx={{ willChange: "transform" }}
            style={useSpring(translate(audio))}
          >
            {renderSpecial(translation)}
          </a.td>
          {language.audio ? (
            <td>
              <a.div
                sx={{
                  willChange: "opacity",
                  button: { bg: "transparent !important" },
                }}
                style={useSpring(fade(audio))}
              >
                <AudioButton
                  index={index}
                  inline={false}
                  string={translation.characters}
                  language={language.id}
                />
              </a.div>
            </td>
          ) : null}
        </TableRow>
      ) : (
        <TableRow
          key={base}
          base={base}
          className="edit-row"
          translation={translation}
          sx={{
            "td:first-of-type": {
              width: language.audio ? "28%" : "33%",
            },
            "td:last-of-type": {
              width: language.audio ? "60px" : "auto",
            },
          }}
        >
          <td>{base}</td>
          <a.td
            className="hide-mobile"
            sx={{ willChange: "transform" }}
            style={useSpring(translate(audio))}
          >
            {translation.masculine}
          </a.td>
          <a.td
            className="hide-mobile"
            sx={{ willChange: "transform" }}
            style={useSpring(translate(audio))}
          >
            {translation.feminine}
          </a.td>
          <a.td className="hide-desktop" style={useSpring(translate(audio))}>
            {translation.hybrid}
          </a.td>
          {language.audio ? (
            <td>
              <a.div
                sx={{ willChange: "opacity" }}
                style={useSpring(fade(audio))}
              >
                <AudioButton
                  index={index}
                  inline={false}
                  string={translation.masculine}
                  language={language.id}
                />
              </a.div>
            </td>
          ) : null}
        </TableRow>
      )
    })
  }

  return (
    <section className="edit-theme" sx={{ variant: "tableSection" }}>
      <Styled.table>
        <thead className="heading-one-col edit-heading">
          <tr>
            {language.special ? (
              <Fragment>
                <th>English</th>
                <a.th style={useSpring(translate(audio))}>{language.id}</a.th>
              </Fragment>
            ) : (
              <Fragment>
                <th>English</th>
                <a.th
                  style={useSpring(translate(audio))}
                  className="hide-mobile"
                >
                  Masculine
                </a.th>
                <a.th
                  style={useSpring(translate(audio))}
                  className="hide-mobile"
                >
                  Feminine
                </a.th>
                <a.th
                  style={useSpring(translate(audio))}
                  className="hide-desktop"
                >
                  {language.id}
                </a.th>
              </Fragment>
            )}
            {language.audio ? <th></th> : null}
          </tr>
        </thead>
        <tbody>{formatCells(data[0].rows)}</tbody>
      </Styled.table>
    </section>
  )
}
