/** @jsx jsx */
import { jsx } from "theme-ui"
import Helmet from "react-helmet"
import { Fragment, useState, useEffect } from "react"
import styled from "@emotion/styled"
import { ContentWrapper, Main, Sidebar } from "gatsby-theme-elements"

import { useFlashCards } from "../../context/FlashContext"
import { useEditMode } from "../../context/EditContext"
import AddButton from "../EditMode/AddButton"
import PostHeader from "../PostHeader"
import PostToolbar from "../PostToolbar"
import SocialShare from "../SocialShare"
import AdBox from "../Ad"
import FormatPicker from "../FormatPicker"
import VocabTable from "./VocabTable"
import VocabSidebar from "./VocabSidebar"
import SEO from "../Seo"
import language from "../../config/language"

const singleColumns = ["Adjectives", "Careers", "Colors"]

export default ({ pageContext }) => {
  const { updateCards } = useFlashCards()
  const { editMode } = useEditMode()
  const [audio, toggleAudio] = useState(true)
  const [format, toggleFormat] = useState(language.formats[0])
  const title = pageContext.table.title
  const isSingle = singleColumns.includes(title)

  useEffect(() => {
    updateCards(pageContext.data)
  }, [])

  return (
    <Fragment>
      <SEO title={`${title} Vocabulary`} />

      {editMode ? (
        <Helmet>
          <body className="edit-mode" />
        </Helmet>
      ) : null}

      <ContentWrapper layout="content-sidebar" sx={{ p: [0, "70px 20px"] }}>
        <Main>
          <PostHeader postType="vocab" title={pageContext.table.tableName} />
          <div
            sx={{
              opacity: 0,
              zIndex: 1,
              position: "relative",
              animation: "fadeInUp 0.5s ease forwards .3s",
            }}
          >
            <ShareWrapper>
              <SocialShare title={title} postType="Vocabulary" size={50} />
              {editMode ? (
                <AddButton label="Add a Word" postType="vocab" />
              ) : null}
            </ShareWrapper>
            <ToolbarWrapper
              sx={{
                p: ["0 20px 30px", "10px 0 40px"],
              }}
            >
              <PostToolbar audio={audio} toggleAudio={toggleAudio} />
              <FormatPicker
                format={format}
                toggleFormat={toggleFormat}
                isSingle={isSingle}
              />
            </ToolbarWrapper>
          </div>
          <div
            sx={{
              opacity: 0,
              animation: "fadeInUp .8s ease forwards .4s",
            }}
          >
            <VocabTable
              audio={audio}
              data={pageContext.data}
              format={format}
              title={title}
            />
          </div>
        </Main>
        <Sidebar>
          <VocabSidebar />
          <AdContainer>
            <AdBox type="small" />
            <AdBox type="large" sticky />
          </AdContainer>
        </Sidebar>
      </ContentWrapper>
    </Fragment>
  )
}

const ShareWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
`

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const AdContainer = styled.div`
  opacity: 0;
  animation: fadeInUp ease 0.5s forwards 0.6s;
`
