/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Fragment } from "react"
import { animated as a, useSpring } from "react-spring"

import TableRow from "./TableRow"
import AudioButton from "../AudioButton"
import { translate, fade } from "../../utils/animate"
import language from "../../config/language"
import formatter from "./formatter"

export default ({ data, audio, format }) => {
  function renderSpecial(translation) {
    switch (format.key) {
      case "all":
        return (
          <Fragment>
            <div className="characters">{translation.characters}</div>
            <div className="romanize">{translation.romanization}</div>
          </Fragment>
        )
      case "romanize":
        return <div>{translation.romanization}</div>
      case "characters":
        return <div className="characters">{translation.characters}</div>
      default:
        return
    }
  }

  function getTranslation({ article, symbol, value }) {
    const formatArticle =
      article.charAt(article.length - 1) === "’" ? `${article}` : `${article} `

    switch (format.key) {
      case "definite":
        return `${formatArticle}${value} ${symbol !== null ? symbol : ""}`
      case "indefinite":
        return formatter(article, symbol, value)
      case "hide":
        return value
      default:
        return
    }
  }

  function renderCells(rows) {
    return rows.map((row, index) => {
      return (
        <TableRow
          className="edit-row"
          key={row.base + index}
          base={row.base}
          translation={row.translation}
          sx={{
            "td:first-of-type": {
              width: language.audio ? "42%" : "50%",
            },
            borderBottom: language.special ? "1px solid #eee" : "none",
            ":last-child": {
              border: "none",
            },
          }}
        >
          <td>{row.base}</td>
          <a.td
            sx={{ willChange: "transform" }}
            style={useSpring(translate(audio))}
          >
            {language.special
              ? renderSpecial(row.translation)
              : getTranslation(row.translation)}
          </a.td>
          {language.audio ? (
            <td>
              <a.div
                sx={{
                  willChange: "opacity",
                  button: { bg: "transparent !important" },
                }}
                style={useSpring(fade(audio))}
              >
                <AudioButton
                  index={index}
                  inline={false}
                  string={
                    language.special
                      ? row.translation.characters
                      : getTranslation(row.translation)
                  }
                  language={language.id}
                />
              </a.div>
            </td>
          ) : null}
        </TableRow>
      )
    })
  }

  return (
    <section className="edit-theme" sx={{ variant: "tableSection" }}>
      <Styled.table>
        <thead>
          <tr>
            <th className="table-heading edit-heading" colSpan="3">
              {data.category}
            </th>
          </tr>
          <tr sx={{ display: "none" }}>
            <th>Base</th>
            <th>Translation</th>
            {language.audio ? <th>Audio</th> : null}
          </tr>
        </thead>
        <tbody>{renderCells(data.rows)}</tbody>
      </Styled.table>
    </section>
  )
}
