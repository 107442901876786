/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"

import { useEditMode } from "../../context/EditContext"
import OneColumn from "./OneColumn"
import TwoColumn from "./TwoColumn"

const singleColumns = ["Adjectives", "Careers", "Colors"]

export default ({ data, audio, title, format }) => {
  const isSingle = singleColumns.includes(title)
  const { editMode } = useEditMode()

  function formatTables(column) {
    if (isSingle) {
      return (
        <OneColumn
          audio={audio}
          data={data}
          editMode={editMode}
          format={format}
        />
      )
    }
    return data.map(section => {
      if (section.column === column) {
        return (
          <TwoColumn
            key={section.category}
            audio={audio}
            data={section}
            editMode={editMode}
            format={format}
          />
        )
      }
      return null
    })
  }

  const gridPartial = isSingle
    ? {
        display: "block",
      }
    : {
        display: "grid",
        gridTemplateColumns: ["1fr", "1fr 1fr"],
        columnGap: 30,
      }

  return (
    <div sx={{ ...gridPartial }}>
      {isSingle ? (
        <div>{formatTables()}</div>
      ) : (
        <Fragment>
          <div>{formatTables(1)}</div>
          <div>{formatTables(2)}</div>
        </Fragment>
      )}
    </div>
  )
}
