export default (article, symbol, value) => {
  switch (process.env.GATSBY_LANGUAGE) {
    case "French":
      return french(article, symbol, value)
    case "Spanish":
      return spanish(article, value)
    default:
      return
  }
}

function french(article, symbol, value) {
  switch (article) {
    case "le":
      return `un ${value}`
    case "la":
      return `une ${value}`
    case "l’":
      return symbol === "(m)" ? `un ${value}` : `une ${value}`
    case "les":
      return `des ${value} ${symbol !== null ? symbol : ""}`
    default:
      return value
  }
}

function spanish(article, value) {
  switch (article) {
    case "el":
      return `un ${value}`
    case "la":
      return `una ${value}`
    case "los":
      return `unos ${value}`
    case "las":
      return `unas ${value}`
    default:
      return value
  }
}

// TODO Revisit this

function italian(article, value, symbol) {
  switch (article) {
    case "il":
    case "lo":
      return `uno ${value}`
    case "la":
      return `una ${value}`

    case "i":
    case "gli":
      return `unos ${value}`
    case "le":
      return `unas ${value}`
    default:
      return value
  }
}
