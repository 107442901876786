/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"

import { useEditMode } from "../../context/EditContext"

export default ({ label, postType }) => {
  const { toggleEditModal } = useEditMode()

  return (
    <AddButton
      sx={{
        bg: "edit",
        fontFamily: "heading",
        width: ["100%", "240px"],
        mx: ["20px", 0],
        mb: ["20px", 0],
      }}
      onClick={() => toggleEditModal("new", postType)}
    >
      {label}
    </AddButton>
  )
}

const AddButton = styled.button`
  color: #fff;
  border: none;
  border-radius: 2px;
  padding: 15px 30px;
  font-size: 18px;
`
