/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { useState, useRef, useLayoutEffect } from "react"
import { Link } from "gatsby"
import { animated as a, useSpring } from "react-spring"

import { vocabMenu } from "../Layout/menus"

export default () => {
  const menuRef = useRef(null)
  const [show, set] = useState(false)
  const [menuHeight, setMenuHeight] = useState(400)

  useLayoutEffect(() => {
    setMenuHeight(menuRef.current.clientHeight)
  }, [])

  const reveal = useSpring({
    height: show ? menuHeight + 58 : 143,
  })

  return (
    <Fragment>
      <a.div
        style={reveal}
        sx={{ variant: "sideMenu", overflow: "hidden", mb: "5px" }}
      >
        <h2>Vocabulary Lists</h2>
        <ul ref={menuRef}>
          {vocabMenu.map(item => (
            <li key={item.path}>
              <Link to={item.path}>{item.label}</Link>
            </li>
          ))}
        </ul>
      </a.div>
      <button
        sx={{
          border: "1px solid #dcdcdc",
          outline: "none",
          cursor: "pointer",
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.03)",
          color: "primary",
          p: 15,
          fontFamily: "heading",
          fontSize: "15px",
          letterSpacing: 1,
          textTransform: "uppercase",
          fontWeight: 600,
          bg: "#f9f9f9",
          width: "100%",
          mb: 40,
        }}
        onClick={() => set(!show)}
      >
        {show ? "Less" : "More"}
      </button>
    </Fragment>
  )
}
