/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import * as vocabURLs from "../assets/icons/vocab"
import * as phraseURLs from "../assets/icons/phrasebook"

export default ({ postType, title }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        opacity: 0,
        animation: "fadeInUp ease .6s forwards",
        mb: 30,
        p: ["30px 20px 10px", "0 0 20px"],
      }}
    >
      <div
        id="post-image"
        className="edit-theme"
        sx={{
          bg: "#f9f9f9",
          border: "1px solid gainsboro",
          width: [75, 145],
          mr: [20, 50],
          p: [10, 25],
          img: {
            m: "auto",
          },
        }}
      >
        <img
          src={
            postType === "vocab"
              ? vocabURLs[title.toLowerCase()]
              : phraseURLs[title.split(" ")[0].toLowerCase()]
          }
          alt={title}
        />
      </div>
      <div>
        <h1 sx={{ fontSize: ["2em", "3em"] }}>{title}</h1>
        <Link
          to={postType === "vocab" ? "/vocabulary" : "/phrasebook"}
          sx={{
            textTransform: "uppercase",
            letterSpacing: [4, 5],
            textDecoration: "none",
            fontWeight: 600,
            color: "#bbb",
            fontFamily: "heading",
            ml: "5px",
            fontSize: ["16px", "20px"],
          }}
        >
          {postType === "vocab" ? "Vocabulary" : "Phrasebook"}
        </Link>
      </div>
    </div>
  )
}
