/** @jsx jsx */
import { jsx } from "theme-ui"

import downURL from "../assets/icons/down.svg"
import language from "../config/language"

export default ({ format, toggleFormat, isSingle }) => {
  return (
    <div
      className="edit-theme"
      sx={{
        width: 240,
        flex: [1, "none"],
        display: isSingle && !language.special ? "none" : "inline-block",
        position: "relative",
        "&:hover .dropdown, &:focus .dropdown, &:active .dropdown": {
          display: "block",
        },
        button: {
          bg: "#fff",
          fontSize: 16,
          cursor: "pointer",
          width: "100%",
          textAlign: "left",
          fontFamily: "heading",
          letterSpacing: ".5px",
          outline: "none",
        },
      }}
    >
      <button
        id="format-picker"
        className="edit-theme"
        sx={{
          height: 52,
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "0 0 0 15px",
          borderRadius: 2,
          border: "1px solid #ccc",
          color: "#676767",
        }}
      >
        {format.label}
        <div
          className="edit-theme"
          sx={{
            bg: "#f9f9f9",
            height: "100%",
            borderLeft: "1px solid #dcdcdc",
            px: "8px",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            right: 0,
            top: 0,
          }}
        >
          <img src={downURL} sx={{ height: 20 }} alt="Down Arrow" />
        </div>
      </button>
      <div
        className="dropdown"
        sx={{
          display: "none",
          bg: "#fff",
          position: "absolute",
          width: "100%",
          boxShadow: "0 5px 11px 0 rgba(0,0,0,.07)",
          zIndex: 3,
          border: "1px solid #e4e4e4",
          borderTop: "none",
          button: {
            background: "none",
            border: "none",
            borderBottom: "1px solid gainsboro",
            p: "10px 15px",
            "&:hover": {
              background: "#f9f9f9",
            },
          },
        }}
      >
        {language.formats.map(item => (
          <button key={item.label} onClick={() => toggleFormat(item)}>
            {item.label}
          </button>
        ))}
      </div>
    </div>
  )
}
