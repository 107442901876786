/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"

import { useFlashCards } from "../context/FlashContext"
import { useEditMode } from "../context/EditContext"
import ToolButton from "./ToolButton"
import { FlashCardIcon, EditIcon } from "../assets/icons"
import AudioToggle from "../assets/icons/AudioToggle"

export default ({ audio, toggleAudio }) => {
  const { toggleFlashCards } = useFlashCards()
  const { editMode, toggleEditMode } = useEditMode()

  return (
    <Toolbar
      id="post-toolbar"
      sx={{
        mb: [15, 0],
        width: ["100%", "auto"],
      }}
    >
      <ToolButton
        className="edit-theme"
        onClick={() => toggleFlashCards()}
        icon={<FlashCardIcon />}
        text="Flashcards"
        marginRight={[10, 15]}
      />
      <ToolButton
        className="edit-theme"
        onClick={() => toggleEditMode()}
        id="toggle-edit-mode"
        icon={<EditIcon />}
        text={editMode ? "Close Editor" : "Editor"}
        marginRight={[10, 15]}
      />
      <ToolButton
        className="edit-theme"
        onClick={() => toggleAudio(!audio)}
        icon={<AudioToggle active={audio} />}
        text={audio ? "Hide Audio" : "Show Audio"}
      />
    </Toolbar>
  )
}

const Toolbar = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`
