/** @jsx jsx */
import { jsx } from "theme-ui"

import { useEditMode } from "../../context/EditContext"

export default ({ children, base, translation, ...props }) => {
  const { editMode, toggleEditModal } = useEditMode()
  return (
    <tr
      onClick={() =>
        editMode ? toggleEditModal("edit", "vocab", base, translation) : null
      }
      {...props}
    >
      {children}
    </tr>
  )
}
